/* eslint-disable no-param-reassign */
import _ from 'lodash'
import moment from 'moment-timezone'
import { adjustForStartOfDay, getPythonWeekday } from 'mgr/lib/utils/MomentUtils'
import { WEEK_INDEX_TO_TITLE } from 'svr/constants'
import type { TimeRange, OrderingMenu, ExcludedDateRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface MomentTimeRange {
  start: moment.Moment
  end: moment.Moment
}

export interface WeeklyAvailableHoursRange {
  day: string
  hours: (TimeRange | MomentTimeRange)[]
  rangesFormatted: string[]
  isToday: boolean
}

export interface TimestampRange {
  type: 'start' | 'end'
  ts: moment.Moment
}

export interface MenuAvailability {
  name: string
  dropdownLabel: string
  availableHours: WeeklyAvailableHoursRange[]
}

const formatHourMinute = (momentTime: moment.Moment, locale = 'en_US') => `${momentTime.format(locale === 'en_US' ? 'h:mm A' : 'LT')}`

const formatDailyHoursRanges = (
  hoursRanges: (TimeRange | MomentTimeRange)[],
  locale: string,
  mutableAllMenusIntersectionHourRanges: MomentTimeRange[]
) => {
  const emptyRange = ['-']
  if (hoursRanges.length) {
    return hoursRanges.map(startEndObj => {
      const { start, end } = startEndObj
      const startMoment = moment(start, 'h:m:s')
      const endMoment = moment(end, 'h:m:s')
      mutableAllMenusIntersectionHourRanges.push({
        start: startMoment,
        end: endMoment,
      })
      const startFormatted = formatHourMinute(startMoment, locale)
      const endFormatted = formatHourMinute(endMoment, locale)
      return `${startFormatted} - ${endFormatted}`
    })
  }
  return emptyRange
}

const buildTimestampsRange = (hoursRanges: MomentTimeRange[]): TimestampRange[] =>
  hoursRanges.reduce((acc: TimestampRange[], modifier: MomentTimeRange) => {
    const { start: startMoment, end: endMoment } = modifier
    acc.push({
      type: 'start',
      ts: startMoment,
    })
    acc.push({
      type: 'end',
      ts: endMoment,
    })
    return acc
  }, []) as TimestampRange[]

const buildIntersectionRanges = (globalRangeSorted: TimestampRange[]) => {
  let counter = 0
  const intersectedRanges = [] as MomentTimeRange[]
  globalRangeSorted.reduce((acc: moment.Moment[], modifier: TimestampRange) => {
    if (!acc.length) {
      acc.push(modifier.ts)
      counter += 1
      return acc
    } else if (modifier.type === 'start') {
      counter += 1
      return acc
    } else if (modifier.type === 'end') {
      if (counter > 1) {
        counter -= 1
        return acc
      } else if (counter === 1) {
        acc.push(modifier.ts)
        intersectedRanges.push({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          start: acc[0]!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          end: acc[1]!,
        })
        counter -= 1
        return []
      }
    }
    return acc
  }, [])
  return intersectedRanges
}

const mergeCollidingRanges = (intersectedRanges: MomentTimeRange[]) =>
  intersectedRanges.reduce((acc: MomentTimeRange[], modifier: MomentTimeRange) => {
    if (!acc.length) {
      return [modifier]
    }
    const prevRange = acc[acc.length - 1]
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const endHourPrevRange = prevRange!.end
    const startHourCurrRange = modifier.start
    if (endHourPrevRange.isSame(startHourCurrRange)) {
      // merge prevRange and currentx§ range into 1
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const startHourPrevRange = acc[acc.length - 1]!.start
      const endHourCurrRange = modifier.end
      acc[acc.length - 1] = {
        start: startHourPrevRange,
        end: endHourCurrRange,
      }
    } else {
      acc.push(modifier)
    }
    return acc
  }, [])

/**
 * Use valid parenthesis check approach with counter to create hours intersection (every start has an end; N(starts) === N(ends))
 * buildTimestampsRange(): create array with all timestamps and types (start, end)
 * Then sort by ts in ascending order
 * buildIntersectionRanges(): Generate intersections based on subarrays where N(start) === N(end)
 * (start1, start2, end1, end2) -> [(start1, end2)]
 * (start1, end1, start2, end2) -> [(start1, end1), (start2, end2)]
 * This will always be valid, assuming that end is always greater than the start in every input start-end pair
 * mergeCollidingRanges(): Sometimes end1 === start2, So there is chance that we will get 2 ranges e.g ([01:00, 03:00],[03:00, 05:00])
 * that collide on start and end
 * mergeCollidingRanges will merge such ranges
 */
const computeAllMenusTimeAvailableHoursIntersection = (
  allMenusIntersectionAvailableHours: Record<number, MomentTimeRange[]>,
  startOfDayHour: number
) => {
  for (const [key, hoursRanges] of Object.entries(allMenusIntersectionAvailableHours)) {
    const globalRange = buildTimestampsRange(hoursRanges)
    const globalRangeSorted = _.sortBy(globalRange, o => adjustForStartOfDay(o.ts, startOfDayHour, o.type === 'end'))
    const intersectedRanges = buildIntersectionRanges(globalRangeSorted)
    allMenusIntersectionAvailableHours[Number(key)] = mergeCollidingRanges(intersectedRanges)
  }
}

const computeWeeklyExcludedDayIndexes = (
  excludedDateRanges: ExcludedDateRange[] | undefined = [],
  todayMoment: moment.Moment,
  menuDateRangeFromMoment: moment.Moment,
  menuHasntStarted: boolean
): Set<number> => {
  const startingDate = menuHasntStarted ? menuDateRangeFromMoment : todayMoment
  const startingDatePlusWeek = moment(startingDate).add(6, 'days')
  return new Set(
    _.flatten(
      excludedDateRanges.flatMap(item => {
        if (item.to < startingDate || item.from > startingDatePlusWeek) {
          return []
        }
        const excludedFromDayIndex = item.from < startingDate ? getPythonWeekday(startingDate) : getPythonWeekday(item.from)
        const excludedToDayIndex = item.to > startingDatePlusWeek ? getPythonWeekday(startingDatePlusWeek) : getPythonWeekday(item.to)

        if (excludedFromDayIndex <= excludedToDayIndex) {
          return _.range(excludedFromDayIndex, excludedToDayIndex + 1)
        }
        return [..._.range(excludedToDayIndex + 1), ..._.range(excludedFromDayIndex, 7)]
      })
    )
  )
}

const formatMenuWeeklyAvailableHoursRanges = (
  daysHours: Record<number, (TimeRange | MomentTimeRange)[]>,
  locale: string,
  mutableAllMenusIntersectionHours: Record<number, MomentTimeRange[]>,
  todayMoment: moment.Moment,
  menuLengthLessThan7Days: boolean,
  menuDateRangeFromMoment: moment.Moment,
  menuDateRangeToMoment: moment.Moment | null,
  excludedDateRanges: ExcludedDateRange[]
): WeeklyAvailableHoursRange[] => {
  const todayDayIndexPythonic = getPythonWeekday(moment())
  const daysTillMenuEnds = menuDateRangeToMoment ? menuDateRangeToMoment.diff(todayMoment, 'days') : 999
  const menuEndsIn7Days = daysTillMenuEnds < 7
  const menuFromDiffTodayDays = menuDateRangeFromMoment.diff(todayMoment, 'days')

  const menuHasntStarted = menuFromDiffTodayDays > 0

  const menuFromDayIndexPythonic = getPythonWeekday(menuDateRangeFromMoment)
  const menuToDayIndexPythonic = menuLengthLessThan7Days ? getPythonWeekday(menuDateRangeToMoment) : 0
  const weeklyExcludedDayIndexesSet = computeWeeklyExcludedDayIndexes(
    excludedDateRanges,
    todayMoment,
    menuDateRangeFromMoment,
    menuHasntStarted
  )
  return Object.keys(daysHours)
    .map(Number)
    .map(dayIndex => {
      // Start calculation not from 0 dayIndex, but from todayDayIndexPythonic
      const dayIndexFromToday =
        dayIndex + todayDayIndexPythonic <= 6 ? dayIndex + todayDayIndexPythonic : dayIndex + todayDayIndexPythonic - 7
      mutableAllMenusIntersectionHours[dayIndexFromToday] = mutableAllMenusIntersectionHours[dayIndexFromToday] || []
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const hours = daysHours[dayIndexFromToday]!

      // Hide weekdays on <7 days menus that not part of date range
      const hideWeekday =
        menuLengthLessThan7Days &&
        ((menuFromDayIndexPythonic <= menuToDayIndexPythonic &&
          (dayIndexFromToday < menuFromDayIndexPythonic || dayIndexFromToday > menuToDayIndexPythonic)) ||
          (menuFromDayIndexPythonic > menuToDayIndexPythonic &&
            dayIndexFromToday < menuFromDayIndexPythonic &&
            dayIndexFromToday > menuToDayIndexPythonic))

      // Hide menus hours if menu ends this week, or weekday is excluded
      const hideMenuHours =
        weeklyExcludedDayIndexesSet.has(dayIndexFromToday) || hideWeekday || (menuEndsIn7Days && dayIndex > daysTillMenuEnds)

      const dontComputeHours = hideWeekday || hideMenuHours
      const skipAddToAllMenus = dontComputeHours || (menuHasntStarted && menuFromDiffTodayDays > dayIndex)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const allMenusIntersectionHours = skipAddToAllMenus ? [] : mutableAllMenusIntersectionHours[dayIndexFromToday]!
      const rangesFormatted = dontComputeHours
        ? formatDailyHoursRanges([], locale, allMenusIntersectionHours)
        : formatDailyHoursRanges(hours, locale, allMenusIntersectionHours)

      let weekdayDate
      if (!hideWeekday && (menuLengthLessThan7Days || menuHasntStarted)) {
        let daysToAdd
        if (menuFromDayIndexPythonic > dayIndexFromToday) {
          daysToAdd = 7 - menuFromDayIndexPythonic + dayIndexFromToday
        } else {
          daysToAdd = dayIndexFromToday - menuFromDayIndexPythonic
        }
        weekdayDate = moment(menuDateRangeFromMoment).add(daysToAdd, 'days').format('MMM DD')
      }
      return {
        weekdayIndexPythonic: dayIndexFromToday,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        day: WEEK_INDEX_TO_TITLE[dayIndexFromToday]!,
        hours,
        rangesFormatted,
        isToday: dayIndexFromToday === todayDayIndexPythonic,
        weekdayDate,
        hideWeekday,
      }
    })
    .sort((prev, next) => (prev.weekdayIndexPythonic > next.weekdayIndexPythonic ? 1 : -1))
}

const formatIntersectionAvailableHoursRanges = (
  daysHours: Record<number, (TimeRange | MomentTimeRange)[]>,
  locale: string
): WeeklyAvailableHoursRange[] => {
  const todayDayIndexPythonic = getPythonWeekday(moment())
  return Object.keys(daysHours)
    .map(Number)
    .map(dayIndex => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const hours = daysHours[dayIndex]!
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        day: WEEK_INDEX_TO_TITLE[dayIndex]!,
        hours,
        rangesFormatted: formatDailyHoursRanges(hours, locale, []),
        isToday: dayIndex === todayDayIndexPythonic,
      }
    })
}

export const mergeMenuAvailableHours = (menus: OrderingMenu[], locale: string, startOfDayHour: number): MenuAvailability[] => {
  const allMenusIntersection = {
    name: 'Ordering Hours',
    dropdownLabel: 'All menus',
    availableHours: {},
  } as MenuAvailability
  const mutableAllMenusIntersectionHours = {} as Record<number, MomentTimeRange[]>
  const todayMoment = moment().startOf('day')
  const menuAvailability = menus.flatMap(item => {
    const menuDateRangeFromMoment = item.dateRangeFrom
    const menuDateRangeToMoment = item.dateRangeTo
    // Menu has already ended
    if (menuDateRangeToMoment && menuDateRangeToMoment < todayMoment) {
      return []
    }

    const menuLengthLessThan7Days = menuDateRangeToMoment ? menuDateRangeToMoment.diff(menuDateRangeFromMoment, 'days') < 7 : false
    return {
      name: item.name,
      dropdownLabel: item.name,
      availableHours: formatMenuWeeklyAvailableHoursRanges(
        item.availableHours,
        locale,
        mutableAllMenusIntersectionHours,
        todayMoment,
        menuLengthLessThan7Days,
        menuDateRangeFromMoment,
        menuDateRangeToMoment,
        item.excludedDateRanges
      ),
    } as MenuAvailability
  })

  if (menuAvailability.length === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    allMenusIntersection.availableHours = [...menuAvailability[0]!.availableHours]
    return [allMenusIntersection]
  }

  computeAllMenusTimeAvailableHoursIntersection(mutableAllMenusIntersectionHours, startOfDayHour)
  allMenusIntersection.availableHours = formatIntersectionAvailableHoursRanges(mutableAllMenusIntersectionHours, locale)
  return [allMenusIntersection, ...menuAvailability]
}
